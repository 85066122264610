export const colors = [
  {
    name: "green",
    hex: "#219653",
  },
  {
    name: "yellow",
    hex: "#F2C94C",
  },
  {
    name: "orange",
    hex: "#F2994A",
  },
  {
    name: "red",
    hex: "#EB5757",
  },
  {
    name: "blue",
    hex: "#2F80ED",
  },
  {
    name: "lightblue",
    hex: "#56CCF2",
  },
  {
    name: "lightgreen",
    hex: "#6FCF97",
  },
  {
    name: "gray1",
    hex: "#333333",
  },
  {
    name: "gray2",
    hex: "#4F4F4F",
  },
  {
    name: "gray3",
    hex: "#828282",
  },
  {
    name: "gray4",
    hex: "#BDBDBD",
  },
  {
    name: "gray5",
    hex: "#E0E0E0",
  },
];
